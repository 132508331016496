/**
  * Name: Axies - NFT Marketplace React Template
  * Version: 1.0.5
  * Author: Themesflat
  * Author URI: http://www.themesflat.com
*/
@import "ntfs.css?cache=1";
@import "shortcodes.css?cache=1";
@import "responsive.css?cache=1";
@import "animate.css?cache=1";
@import "font-awesome.css?cache=1";
@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "bootstrap.css?cache=1";
@import "toastify.css?cache=1";
/**
  	* Reset Browsers
    * General
	* Elements
  	* Forms
	* Typography
	* Extra classes
	* link style
	* Root
	* Header
	* Site Logo
    * Menu
    * Header Fixed
    * Pagination
    * Footer
    * Scroll Top
    * Widget
*/

/* Reset Browsers
-------------------------------------------------------------- */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  scrollbar-color: darkgrey inherit;
  scrollbar-width: thin;
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

::-webkit-scrollbar-track {
  background-color: inherit;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  font-size: 62.5%;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background: var(--bg-section);
  line-height: 1;
  padding-right: 0 !important;
  height: 100%;
  width: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th,
td {
  font-weight: normal;
  text-align: left;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

blockquote,
q {
  quotes: none;
}

a img {
  border: 0;
}

img {
  max-width: 100%;
  height: auto;
}

img[src=""],
img:not([src]) {
  opacity: 0;
}

img[src="*"] {
  opacity: 1;
}

select {
  max-width: 100%;
}

/* General
-------------------------------------------------------------- */

body,
button,
input,
select,
textarea {
  font-family: "Urbanist", sans-serif;
  color: var(--primary-color2);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
}

p {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: var(--primary-color4);
}

strong,
b,
cite {
  font-weight: bold;
}

dfn,
cite,
em,
i,
blockquote {
  font-style: italic;
}

abbr,
acronym {
  border-bottom: 1px dotted #e0e0e0;
  cursor: help;
}

mark,
ins {
  text-decoration: none;
}

sup,
sub {
  font-size: 75%;
  height: 0;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

small {
  font-size: 75%;
}

big {
  font-size: 125%;
}

address {
  font-style: italic;
  margin: 0 0 20px;
}

code,
kbd,
tt,
var,
samp,
pre {
  margin: 20px 0;
  padding: 4px 12px;
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  overflow-x: auto;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  hyphens: none;
  border-radius: 0;
  height: auto;
}

/* Elements
-------------------------------------------------------------- */

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

hr {
  margin-bottom: 8px;
  border: dashed 1px #ccc;
}

/* List */

ul,
ol {
  padding: 0;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li > ul,
li > ol {
  margin-bottom: 0;
}

li {
  list-style: none;
}

ul li,
ol li {
  padding: 0.1em 0;
}

dl,
dd {
  margin: 0 0 20px;
}

dt {
  font-weight: bold;
}

del,
.disable {
  text-decoration: line-through;
  filter: alpha(opacity=50);
  opacity: 0.5;
}

/* Table */

table,
th,
td {
  border: 1px solid #343444;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  border-width: 1px 0 0 1px;
  margin: 0 0 30px;
  table-layout: fixed;
  width: 100%;
}

caption,
th,
td {
  font-weight: normal;
  text-align: left;
}

th {
  border-width: 0 1px 1px 0;
  font-weight: bold;
}

td {
  border-width: 0 1px 1px 0;
}

th,
td {
  padding: 8px 12px;
}

/* Media */

embed,
object,
video {
  margin-bottom: 20px;
  max-width: 100%;
  vertical-align: middle;
}

p > embed,
p > iframe,
p > object,
p > video {
  margin-bottom: 0;
}

/* Forms
-------------------------------------------------------------- */
/* Fixes */

button,
input {
  line-height: normal;
}

button,
input,
select,
textarea {
  font-size: 100%;
  line-height: inherit;
  margin: 0;
  vertical-align: baseline;
}

div.form-error {
  margin-top: 8px;
  font-size: 12px;
  color: var(--error-color);
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

input,
textarea,
select {
  font-size: 14px;
  max-width: 100%;
  background-image: -webkit-linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
}

textarea {
  overflow: auto;
  vertical-align: top;
}

input[type="checkbox"] {
  display: inline;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  line-height: 1;
  cursor: pointer;
  -webkit-appearance: button;
  border: 0;
}

input[type="checkbox"],
input[type="radio"] {
  padding: 0;
  width: 18px;
  height: 18px;
  margin-right: 11px;
  cursor: pointer;
  vertical-align: sub;
}

input[type="search"] {
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* Remove chrome yellow autofill */

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #f7f7f7 inset;
}

/* Reset search styling */

input[type="search"] {
  outline: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

/* Contact Form 7 */

.wpcf7-form select,
.wpcf7-form textarea,
.wpcf7-form input {
  margin-bottom: 0;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-appearance: none;
}

.select {
  position: relative;
  overflow: hidden;
}

.select::after {
  font-family: "Font Awesome 5 Pro";
  content: "\f078";
  font-size: 14px;
  right: 10px;
  font-weight: 600;
  color: #1f1f2c;
  display: block;
  position: absolute;
  background: 0 0;
  top: 50%;
  pointer-events: none;
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

select option {
  font-size: 15px;
  line-height: 24px;
  color: #000;
  background: var(--bg-section);
}

.is_dark select option {
  color: #fff;
}

select option:checked {
  background: var(--primary-color);
}

textarea,
select,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  border: 1px solid rgba(138, 138, 160, 0.3);
  outline: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-size: 18px;
  line-height: 28px;
  border-radius: 4px;
  padding: 13px 15px;
  width: 100%;
  background: var(--primary-color12);
  color: #8a8aa0;
}

/* Input focus */
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus {
  border: 1px solid #8a8aa0;
  outline: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

textarea::placeholder,
input[type="text"]::placeholder,
input[type="password"]::placeholder,
input[type="datetime"]::placeholder,
input[type="datetime-local"]::placeholder,
input[type="date"]::placeholder,
input[type="month"]::placeholder,
input[type="time"]::placeholder,
input[type="week"]::placeholder,
input[type="number"]::placeholder,
input[type="email"]::placeholder,
input[type="url"]::placeholder,
input[type="search"]::placeholder,
input[type="tel"]::placeholder,
input[type="color"]::placeholder {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #8a8aa0;
  font-style: italic;
}

/* Button */
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  background-color: var(--primary-color3);
  color: #fff;
  border-radius: var(--border-radius-md);
  padding: 1rem 3rem;
  border: 1px solid var(--primary-color3);
  display: inline-block;
  -webkit-appearance: none;
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

/* Button hover + focus */
button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover,
button:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus {
  outline: 0;
  border: 1px solid var(--hover-color3);
  background-color: var(--hover-color3);
}

button:disabled {
  background-color: var(--disabled-color3);
  border: 1px solid var(--disabled-color3);
  color: var(--primary-color7);
}

button.btn.small {
  padding: 15px 15px;
}

/* Placeholder color */
::-webkit-input-placeholder {
  color: #8a8aa0;
}

:-moz-placeholder {
  color: #8a8aa0;
}

::-moz-placeholder {
  color: #8a8aa0;
  opacity: 1;
}

.error {
  font-size: 16px;
  color: var(--error-color);
  margin-bottom: 10px;
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

/* wrapper for label, input, error */
form .field-container {
  margin-bottom: 24px;
}

.multi-field-container .button {
  font-family: "Courier New", Courier, monospace;
}

form .number-comparator {
  display: flex;
}

form .number-comparator input {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

form .number-comparator .comparator-icons {
  display: inline-grid;
}

form .number-comparator .comparator-icons div {
  font-size: 18px;
  padding: 4px 10px;
  border-radius: 0px;
  background-color: var(--primary-color3);
  cursor: pointer;
}

form .number-comparator .comparator-icons div.disabled {
  background-color: var(--primary-color3d);
  color: #8a8aa0;
}

form .number-comparator .comparator-icons .gt {
  border-top-right-radius: 10px;
}

form .number-comparator .comparator-icons .lt {
  border-bottom-right-radius: 10px;
}

form .field-tags .field-tag {
  display: inline-block;
}

form .field-tags .field-tag:not(:last-child) {
  border-right: var(--primary-color2) solid 2px;
}

form .field-tags .field-tag label {
  font-size: 18px;
  padding: 0px 8px;
}

/* Since FF19 lowers the opacity of the placeholder by default */

:-ms-input-placeholder {
  color: #8a8aa0;
}

/* Typography
-------------------------------------------------------------- */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

/* h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: inherit
} */

/* Extra classes
-------------------------------------------------------------- */
.msg-success {
  background: linear-gradient(to right, #e250e5, #4b50e6, #e250e5);
  background-clip: text;
  -webkit-background-clip: text;
  font-weight: 700;
  background-size: 200% 200%;
  animation: rainbow 2s ease-in-out infinite;
  color: rgb(0 0 0 / 0);
  transition: color 0.2s ease-in-out;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

label.error {
  color: red;
}

.category .swiper-button-next:after,
.category .swiper-button-prev:after,
.live-auctions .swiper-button-next:after,
.live-auctions .swiper-button-prev:after {
  font-family: "Font Awesome 5 Pro";
  font-size: 20px;
}

.category .swiper-button-next:after,
.live-auctions .swiper-button-next:after {
  content: "\f061";
}

.category .swiper-button-prev:after,
.live-auctions .swiper-button-prev:after {
  content: "\f060";
}

.flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.wrap-inner {
  display: block;
  width: 100%;
}

.center,
.text-center {
  text-align: center;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.pd-0 {
  padding: 0 !important;
}

.mg-t-29 {
  margin-top: 29px;
}

.mg-bt-50 {
  margin-bottom: 50px;
}

.mg-bt-60 {
  margin-bottom: 60px;
}

.mg-bt-62 {
  margin-bottom: 62px;
}

.mg-bt-10 {
  margin-bottom: 10px;
}

.mg-r-12 {
  margin-right: 12px;
}

.mg-bottom-0 {
  margin-bottom: 0;
}

.mg-t16 {
  margin-top: 16px;
}

.mg-bt-32 {
  margin-bottom: 32px !important;
}

.mg-bt-12 {
  margin-bottom: 12px;
}

.mg-bt-2 {
  margin-bottom: 2px;
}

.mg-bt-21 {
  margin-bottom: 21px;
}

.mg-l-39 {
  margin-left: 39px;
}

.mg-bt-31 {
  margin-bottom: 31px;
}

.mg-bt-43 {
  margin-bottom: 43px;
}

.mg-bt-23 {
  margin-bottom: 23px;
}

.mg-bt-16 {
  margin-bottom: 16px !important;
}

.mg-bt-20 {
  margin-bottom: 20px;
}

.mg-bt-30 {
  margin-bottom: 30px;
}

.mg-bt-13 {
  margin-bottom: 13px;
}

.mg-t-24 {
  margin-top: 24px;
}

.mg-t-20 {
  margin-top: 20px;
}

.mg-t-22 {
  margin-top: 22px;
}

.mg-t-15 {
  margin-top: 15px;
}

.mg-t-21 {
  margin-top: 21px;
}

.mg-t-37 {
  margin-top: 37px;
}

.mg-t-9 {
  margin-top: -9px;
}

.mg-t-4 {
  margin-top: -4px;
}

.mg-t-2 {
  margin-top: -2px;
}

.mg-t2 {
  margin-top: 2px;
}

.mg-t32 {
  margin-top: 32px;
}

.mg-bt-24 {
  margin-bottom: 24px;
}

.mg-bt-22 {
  margin-bottom: 22px;
}

.mg-bt-40 {
  margin-bottom: 40px;
}

.mg-bt-41 {
  margin-bottom: 41px;
}

.mg-bt-44 {
  margin-bottom: 44px;
}

.pad-r-50 {
  padding-right: 50px;
}

.pad-t-23 {
  padding-top: 23px;
}

.pad-t-24 {
  padding-top: 24px;
}

.pad-t-17 {
  padding-top: 17px;
}

.pad-t-20 {
  padding-top: 20px;
}

.pad-t-4 {
  padding-top: 4px;
}

.pad-b-60 {
  padding-bottom: 60px !important;
}

.pad-b-54 {
  padding-bottom: 54px !important;
}

.pad-b-74 {
  padding-bottom: 74px !important;
}

.mg-t-40 {
  margin-top: 40px;
}

.mg-t-36 {
  margin-top: 36px;
}

.mg-bt-18 {
  margin-bottom: 18px;
}

.pad-l-7 {
  padding-left: 7px;
}

.pad-0-15 {
  padding: 0 15px;
}

.mg-r-3 {
  margin-right: 3px;
}

.mg-r-1 {
  margin-right: 1px;
}

.color-47A432 {
  background-color: #47a432 !important;
}

.color-9835FB {
  background-color: #9835fb !important;
}

.color-DF4949 {
  background-color: #df4949 !important;
}

.no-box-shadown {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.pad-b-60 {
  padding-bottom: 60px !important;
}

.pad-b-20 {
  padding-bottom: 20px !important;
}

.pad-400 {
  padding: 0 400px;
}

.pad-420 {
  padding: 0 420px;
}

.mg-t-10 {
  margin-top: 10px;
}

.mg-l-8 {
  margin-left: 10px;
}

.mg-r-8 {
  margin-right: 10px;
}

.mg-r-30 {
  margin-right: 30px;
}

.mg-t-11 {
  margin-top: 11px !important;
}

.mg-t-12 {
  margin-top: 12px !important;
}

.mg-t-6 {
  margin-top: 6px !important;
}

.pd-t-20 {
  padding-top: 20px;
}

.pd-15 {
  padding: 0 15px;
}

.bg-style {
  background-color: var(--primary-color5) !important;
}

.bg-style2 {
  background-color: var(--bg-section) !important;
}

.bg-style3 {
  background-color: #f8f8f8;
}
.is_dark .bg-style3 {
  background-color: #1f1f2c !important;
}

.home-1 .bg-home {
  background-color: var(--primary-color5) !important;
}

.mg-t-42 {
  margin-top: 42px;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-11 {
  padding-bottom: 11px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.pb-23 {
  padding-bottom: 23px !important;
}

.pb-39 {
  padding-bottom: 39px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pd-30 {
  padding: 30px;
}

.pd-40 {
  padding: 40px;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-17 {
  padding-bottom: 17px !important;
}

.pb-18 {
  padding-bottom: 18px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-22 {
  padding-bottom: 22px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.mt-21 {
  margin-top: 21px;
}

.mt-29 {
  margin-top: 29px;
}

.mb-11 {
  margin-bottom: 11px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mt-6 {
  margin-top: 6px;
}

.mt-9 {
  margin-top: 9px;
}

.mt-21 {
  margin-top: 21px;
}

.pt-16 {
  padding-top: 16px;
}

.pt-24 {
  padding-top: 24px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mg-bt-3 {
  margin-bottom: 3px;
}

.pl-17 {
  padding-left: 17px;
}

.pl-34 {
  padding-left: 34px;
}

.pl-51 {
  padding-left: 51px;
}

.pl-68 {
  padding-left: 68px;
}

.pl-20 {
  padding-left: 20px;
}

.mg-style2 {
  margin-left: -3px;
  margin-right: -3px;
}

#wrapper {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  height: 100%;
}

.color-popup {
  color: var(--primary-color2);
}

/* link style
-------------------------------------------------------------- */
a {
  text-decoration: none;
  color: var(--primary-color2);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  letter-spacing: 0.1px;
}

a:hover,
a:focus {
  color: var(--primary-color3);
  text-decoration: none;
  outline: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.exp {
  background: linear-gradient(to right, #e250e5, #4b50e6, #e250e5);
  background-clip: text;
  -webkit-background-clip: text;
  font-weight: 700;

  background-size: 200% 200%;
  animation: rainbow 2s ease-in-out infinite;
  color: var(--primary-color2);
  transition: color 0.2s ease-in-out;
}

.exp:hover {
  color: rgba(0, 0, 0, 0);
}

@keyframes rainbow {
  0% {
    background-position: left;
  }

  50% {
    background-position: right;
  }

  100% {
    background-position: left;
  }
}

.link-style-1 a {
  text-decoration: none;
  color: var(--primary-color4);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.link-style-1 a:hover,
.link-style-1 a:focus {
  color: var(--primary-color3);
  text-decoration: none;
  outline: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* Root
-------------------------------------------------------------- */

:root {
  /* color */
  --primary-color: #fff;
  --primary-color2: #1f1f2c;
  --primary-color3: #5f13f6;
  --primary-color3d: #372ca9;
  --primary-color4: #7a798a;
  --primary-color5: #f8f8f8;
  --primary-color6: #14141f;
  --primary-color7: #f8f8f8;
  --primary-color8: #7a798a;
  --primary-color9: #7a798a;
  --primary-color10: #fff;
  --primary-color11: #569e9e;
  --primary-color11d: #498686;
  --primary-color12: #fff;
  --bg-section: #fff;
  --bg-section2: #f8f8f8;
  --bg-section3: #c8c8c8;
  --error-color: rgb(255, 0, 0);
  --disabled-color3: #211b5c;
  --hover-color: #f2f2f2;
  --hover-color2: #6c5fff;
  --hover-color3: #4313b4;

  /* templating */
  --border-radius-md: 12px;
}

.is_dark {
  --primary-color: #222333;
  --primary-color2: #fff;
  --primary-color4: rgba(255, 255, 255, 0.9);
  --primary-color5: #030303;
  --primary-color6: #ffffff;
  --primary-color7: #343444;
  --primary-color8: #fff;
  --primary-color9: #8a8aa0;
  --primary-color10: #fff;
  --primary-color11: #569e9e;
  --primary-color11d: #498686;
  --primary-color12: #1f1f2c;
  --bg-section: #14141f;
  --bg-section2: #14141f;
  --bg-section3: #202030;
  --hover-color: #343444;
}

/* Header
-------------------------------------------------------------- */
.dark .logo_header {
  width: 135px !important;
  height: 56px !important;
}

header {
  padding-right: 0 !important;
}

.header_1 {
  position: absolute;
  z-index: 90;
  height: 84px;
  width: 100%;
  border-bottom: 1px solid rgba(235, 235, 235, 0.2);
  flex-wrap: wrap;
}

.home-8 .header_1 {
  height: 84px;
  border: none;
  display: flex;
  align-items: center;
}

.header_1-inner {
  position: relative;
  gap: 1rem;
  height: 84px;
}

.header_1 .header_avatar {
  border-radius: 2000px;
  padding-left: 1rem;
  border: solid 2px var(--bg-section);
  background: var(--bg-section);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  z-index: 9;
}

.header_1 .header_avatar:hover {
  border: solid 2px var(--bg-section3);
  background: var(--bg-section3);
}

.header_1 .header_profile {
  position: relative;
}

.header_1 .header_avatar .avatar {
  height: 40px;
  width: 40px;
  border-radius: 2000px;
  -o-object-fit: cover;
  object-fit: cover;
  margin-left: 10px;
}

.header_1 .header_avatar .suiName {
  font-size: 16px;
  color: var(--primary-color11d);
  font-weight: bolder;
}

.header_1 .header_avatar .price {
  font-size: 16px;
  color: var(--primary-color2);
  text-align: right;
}

.header_1 .avatar_popup {
  position: absolute;
  z-index: 90;
  right: 0;
  padding: 2rem 3rem;
  top: 60px;
  overflow: hidden;
  border-radius: var(--border-radius-md);
  background: var(--bg-section);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  -webkit-box-shadow: 0px 17px 13px 0px #192c4b05;
  box-shadow: rgba(0, 0, 0, 0.48) 0px 8px 32px;
  border: solid 1px var(--bg-section);
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(-1rem);
  transform: translateY(-1rem);
  font-size: 16px;
  width: 230px;
}

.header_1 .avatar_popup.visible {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.header_1 .avatar_popup_bg {
  position: absolute;
  display: none;
}

.header_1 .avatar_popup_bg.visible {
  display: block;
}

.header_1 .avatar_popup .links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  row-gap: 20px;
  font-weight: 700;
}

.header_1 .avatar_popup .links a {
  color: var(--primary-color2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header_1 .avatar_popup .links a i {
  margin-right: 5px;
  font-size: 20px;
}

.header_1 .avatar_popup .links a:hover {
  color: #566ffe;
}

.header_1 .header__notifications .js-notifications-icon {
  cursor: pointer;
}

.header_1 .avatar_popup .links a i {
  margin-right: 10px;
  font-size: 16px;
}

.space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(20px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(20px * var(--cb-space-y-reverse));
}

.space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(14px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(14px * var(--cb-space-y-reverse));
}

.mt-20 {
  margin-top: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: -15px;
}

.mt-19 {
  margin-top: 19px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.ml-10 {
  margin-left: 10px;
}

.avatar_popup .copy-text a,
.avatar_popup .copy-text span {
  color: var(--primary-color2);
  cursor: pointer;
}

/* Site Logo
-------------------------------------------------------------- */

.flat-search-btn div[role="dialog"] * {
  color: black;
}

.flat-search-btn div[role="dialog"] > div > div > div {
  width: 80rem !important;
  max-width: 100% !important;
}

.flat-search-btn {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.flat-search-btn button {
  width: 100%;
}

.main-logo img {
  width: 133px;
  height: 56px;
}

.main-logo img,
.main-logo h2 {
  float: left;
}

.logo-dark {
  display: none;
}

.logo-light {
  display: block;
}

.is_dark .logo-dark {
  display: block;
}

.is_dark .logo-light {
  display: none;
}

.logo-icon {
  height: 56px !important;
  width: 56px !important;
}

.logo-footer img {
  float: left;
}

/* Menu
-------------------------------------------------------------- */
/* Main Nav */
.main-nav ul {
  margin: 0px;
}

.main-nav ul.menu {
  display: flex;
}

.main-nav ul li {
  position: relative;
  list-style: none;
}

.main-nav > ul > li {
  padding: 26px 13px 26px 13px;
}

.main-nav > ul > li > a,
.main-nav > ul > li > p {
  position: relative;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  color: var(--primary-color10);
  padding-right: 15.5px;
}

.main-nav > .menu > li.menu-item-has-children > a::after,
.main-nav > .menu > li.menu-item-has-children > p::after {
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.main-nav > .menu > li.menu-item-has-children.active > p::after {
  display: none;
}

/* Sub Menu */

.main-nav .sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 250px;
  background-color: var(--bg-section);
  border-radius: var(--border-radius-md);
  box-shadow: -2px 6px 23px #b4b4b44f;
  z-index: 9998;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: translateY(15px);
  -moz-transform: translateY(15px);
  -ms-transform: translateY(15px);
  -o-transform: translateY(15px);
  transform: translateY(15px);
}

.main-nav .sub-menu::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 25px;
  height: 15px;
  width: 15px;
  transform: rotate(45deg);
  background-color: var(--bg-section);
}

.main-nav .right-sub-menu {
  left: auto;
  right: 0;
}

.main-nav .sub-menu li a {
  display: block;
  font-size: 18px;
  color: var(--primary-color6);
  padding: 20px;
  font-weight: 700;
}

.main-nav .sub-menu li:not(:last-child) a {
  border-bottom: 1px solid #e1e1e18e;
}

.main-nav li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.main-nav .sub-menu li.current-item a,
.main-nav .sub-menu li a:hover {
  color: var(--primary-color3);
}

.main-nav > ul > li > a:hover {
  color: var(--primary-color3);
}

.main-nav > ul > li .sub-menu li {
  position: relative;
}

.main-nav > ul > li .sub-menu li a {
  transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
}

.main-nav > ul > li .sub-menu li a:hover,
.main-nav > ul > li .sub-menu li.current-item a {
  padding-left: 35px;
}

.main-nav > ul > li .sub-menu li a:hover::after,
.main-nav > ul > li .sub-menu li.current-item a:after {
  width: 12px;
}

.main-nav > ul > li .sub-menu li a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background: var(--primary-color3);
  left: 15px;
  top: 28px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media only screen and (max-width: 1600px) {
  .main-nav {
    left: 18%;
  }

  .main-nav > ul > li {
    padding: 26px 12px 26px 12px;
  }
}

/* Mobile Menu */
@media only screen and (max-width: 991px) {
  .main-nav {
    margin: 0 auto;
    width: 40%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999;
    background-color: var(--bg-section);
    box-shadow: 0px 3px 16px rgb(47 83 109 / 12%);
    transform: translateX(-100%);
    transition: transform 0.5s ease;
  }

  .is_dark .main-nav {
    background: #14141f;
  }

  .main-nav ul.menu {
    flex-direction: column;
  }

  .main-nav > ul > li:first-child {
    border: none;
  }

  .main-nav > ul > li {
    padding: 0;
    border-top: 1px solid rgba(138, 138, 160, 0.3);
  }

  .main-nav ul > li > p,
  .main-nav ul > li > a {
    line-height: 48px;
    padding: 0 15px;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
  }

  .main-nav.active {
    transform: translateX(0);
  }

  .main-nav.active ul {
    flex-direction: column;
  }

  .main-nav.active ul li {
    margin: 0;
    text-align: left;
    cursor: pointer;
    padding: 0;
    border-top: 1px solid rgba(138, 138, 160, 0.3);
    background-color: #fff;
  }

  .main-nav.active ul > li > a {
    color: var(--primary-color6);
    display: inline-block;
    font-size: 16px;
    line-height: 48px;
    padding: 0 15px;
    font-weight: 500;
  }

  .is_dark .main-nav.active ul > li > a {
    color: #fff;
  }

  .main-nav.active ul li:first-child {
    border-top: 0px;
  }

  .main-nav.active ul li > ul > li:first-child {
    border-top: 1px solid rgba(138, 138, 160, 0.3);
  }

  .main-nav.active ul > li.current-menu-item > a {
    color: var(--primary-color3);
  }

  .is_dark .main-nav.active ul > li.current-menu-item > a {
    background: linear-gradient(216.56deg, #e250e5 5.32%, #4b50e6 94.32%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .main-nav.active .current-item > a,
  .main-nav.active ul > li > a:hover {
    color: var(--primary-color3);
  }

  .is_dark .main-nav.active .current-item > a,
  .is_dark .main-nav.active ul > li > a:hover {
    background: linear-gradient(216.56deg, #e250e5 5.32%, #4b50e6 94.32%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .main-nav.active .sub-menu .menu-item a {
    margin-left: 15px;
  }

  .main-nav.active .menu-item-has-children .arrow {
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    font-family: "FontAwesome";
    line-height: 48px;
    position: absolute;
    right: 15px;
    text-align: center;
    top: 0;
    width: 48px;
  }

  .main-nav.active .menu-item-has-children .arrow:before {
    font-family: "Font Awesome 5 Pro";
    content: "\f107";
    color: var(--primary-color2);
  }

  .main-nav.active .menu-item-has-children.current-menu-item .arrow:before {
    color: var(--primary-color3);
  }

  .main-nav.active .menu-item-has-children.current-menu-item .arrow:before {
    background: linear-gradient(216.56deg, #e250e5 5.32%, #4b50e6 94.32%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .main-nav.active .menu-item-has-children .arrow.active {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .main-nav.active .menu-item-has-children .arrow.active:before {
    content: "\f107";
  }

  .main-nav.active ul li {
    background-color: var(--bg-section);
    border-top: 1px solid rgba(138, 138, 160, 0.3);
  }

  .is_dark .main-nav.active ul li {
    background-color: #14141f;
  }

  .main-nav .sub-menu {
    width: 100%;
  }

  .main-nav .sub-menu::before {
    display: none;
  }

  .main-nav.active .sub-menu {
    position: relative;
    display: none;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
  }

  .main-nav.active .menu-item.active .sub-menu {
    display: block;
  }

  .main-nav.active .sub-menu::before {
    display: none;
  }

  .main-nav.active .sub-menu li:not(:last-child) a {
    border: none;
  }
}

@media only screen and (max-width: 767px) {
  .main-nav {
    width: 65%;
  }
}

/* Search icon */
#site-header .header-search-icon {
  display: block;
  font-size: 14px;
  line-height: 100px;
}

.opacity_0 {
  opacity: 0;
}

/* Search Form */
.header-search {
  position: relative;
  margin-right: 36px;
  padding: 14px 0;
}

.header-search .show-search {
  font-size: 20px;
  color: #fff;
}

.top-search.active {
  visibility: visible;
  filter: alpha(opacity=100);
  opacity: 1;
}

.top-search {
  position: absolute;
  right: -16px;
  top: 130%;
  width: 250px;
  opacity: 0;
  visibility: hidden;
}

.search-submit {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  background: none;
  color: #14141f;
  border-radius: 0;
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  transition: all ease 0.3s;
  border: none;
  border-radius: 30px;
  line-height: 20px;
  padding: 15px 15px;
  background: transparent;
}

.search-submit:hover {
  border: none;
  color: #fff;
}

.icon-fl-search-filled {
  color: #14141f;
}

/* Mobile Menu Button */
.mobile-button {
  display: none;
  width: 26px;
  height: 26px;
  background-color: transparent;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}

.mobile-button:before,
.mobile-button:after,
.mobile-button span {
  background-color: #ffffff;
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.mobile-button:before,
.mobile-button:after {
  content: "";
  position: absolute;
  top: 0;
  height: 3px;
  width: 100%;
  left: 0;
  top: 50%;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.mobile-button span {
  position: absolute;
  width: 100%;
  height: 3px;
  left: 0;
  top: 50%;
  overflow: hidden;
  text-indent: 200%;
}

.mobile-button:before {
  -webkit-transform: translate3d(0, -7px, 0);
  -moz-transform: translate3d(0, -7px, 0);
  transform: translate3d(0, -7px, 0);
}

.mobile-button:after {
  -webkit-transform: translate3d(0, 7px, 0);
  -moz-transform: translate3d(0, 7px, 0);
  transform: translate3d(0, 7px, 0);
}

.mobile-button.active span {
  opacity: 0;
}

.mobile-button.active:before {
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  -moz-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg);
}

.mobile-button.active:after {
  -webkit-transform: rotate3d(0, 0, 1, -45deg);
  -moz-transform: rotate3d(0, 0, 1, -45deg);
  transform: rotate3d(0, 0, 1, -45deg);
}

.tf-button-submit:hover {
  opacity: 0.9;
  color: #fff;
}

.is_dark .tf-button-submit:hover {
  color: #fff;
}

/* Header Fixed */

.header_1.is-fixed {
  position: fixed;
  top: -161px;
  left: 0;
  width: 100%;
  box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 30%);
  z-index: 97;
  background: linear-gradient(227.3deg, #8a208c 0%, #181b81 100.84%);
  border: none;
  opacity: 1;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.is_dark .header_1.is-fixed {
  background: #14141f;
  box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 50%);
}

.header_1.is-fixed.is-small {
  top: 0;
}

#header_admin.is-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 30%);
  z-index: 9998;
  background-color: rgb(81 66 252 / 93%);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

/* Pagination
-------------------------------------------------------------- */
.themesflat-pagination {
  margin: 32px 30px;
}

.themesflat-pagination ul {
  margin: 0px;
  position: relative;
}

.themesflat-pagination ul li {
  display: inline-block;
  padding: 0px;
  margin-right: 7px;
}

.themesflat-pagination ul li .page-numbers {
  display: inline-block;
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: #787878;
  text-align: center;
  width: 29px;
  height: 30px;
  border-radius: 3px;
  border: 1px solid var(--primary-color4);
  line-height: 30px;
}

.themesflat-pagination ul li .page-numbers:hover,
.themesflat-pagination ul li .page-numbers.current {
  background-color: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
}

.themesflat-pagination ul li .next,
.themesflat-pagination ul li .prev {
  font-size: 14px;
  font-weight: 700;
  color: #878787;
}

.themesflat-pagination ul li .next:hover,
.themesflat-pagination ul li .prev:hover {
  color: var(--primary-color);
}

/* Footer
-------------------------------------------------------------- */
#footer {
  padding: 80px 0 80px;
  background: var(--bg-section);
}

#footer.style-2 {
  background-color: #ffffff;
}

.is_dark #footer.style-2 {
  background-color: #14141f;
}

/* Scroll Top
-------------------------------------------------------------- */
#scroll-top {
  position: fixed;
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  z-index: 1;
  right: 14px;
  bottom: 23px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  cursor: pointer;
  background-color: var(--primary-color3);
}

.is_dark #scroll-top {
  background: var(--primary-color2);
}

#scroll-top:after {
  content: "\f077";
  font-family: "Font Awesome 5 Pro";
  font-size: 18px;
  color: #ffffff;
  font-weight: 600;
}

.is_dark #scroll-top:after {
  color: var(--primary-color3);
}

#scroll-top:hover {
  transform: translateY(-7%);
}

/* Widget
-------------------------------------------------------------- */
.widget p,
.widget .title-widget {
  color: var(--primary-color2);
}

.fl-dark .widget p,
.fl-dark .widget .title-widget {
  color: var(--primary-color);
}

#footer .widget p {
  font-size: 14px;
  line-height: 22px;
}

.side-bar .widget p {
  font-size: 15px;
}

#footer .title-widget {
  line-height: 26px;
}

#side-bar .title-widget {
  line-height: 30px;
  margin-bottom: 20px;
}

#side-bar .title-widget.style-2 {
  line-height: 26px;
  margin-bottom: 14px;
}

#side-bar .widget {
  margin-bottom: 40px;
}

#side-bar .widget.mgbt-24 {
  margin-bottom: 24px;
}

.sc-widget.style-1 {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
}

#side-bar.style-2 {
  padding-left: 50px;
}

#side-bar .widget.mgbt-0 {
  margin-bottom: 0;
}

#side-bar.style-3 {
  padding-top: 0px;
  padding-right: 55px;
  transition: transform 0.5s ease;
}

.sub-title.small {
  font-size: 14px;
  line-height: 22px;
}

.sub-title.style-2 {
  margin-bottom: 40px;
}

/*widget-logo*/
.widget-logo .logo-footer a {
  color: var(--primary-color3);
}

.widget-logo .logo-footer a {
  font-size: 36px;
  font-weight: bold;
}

.widget-logo .logo-footer {
  margin-bottom: 13px;
  display: inline-block;
}

.widget-logo .sub-widget-logo {
  padding-right: 35px;
  margin-bottom: 26px;
}

/* widget-social */
.widget-social ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.widget-social.style-1 a {
  background-color: var(--primary-color);
  /*#ebebeb;*/
  color: var(--primary-color8);
}

.is_dark .widget-social.style-1 a {
  background-color: #343444;
}

.widget-social.style-1 a:hover {
  color: var(--primary-color);
  background-color: var(--primary-color3);
}

.is_dark .widget-social.style-1 a:hover {
  color: #ffffff;
}

.widget-social.style-1 ul li {
  margin-right: 12px;
}

.widget-social.style-1 ul li:last-child {
  margin-right: 0;
}

.widget-social.style-1 ul li a {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  font-size: 21px;
}

.widget-social.style-1 ul li.style-2 a {
  font-size: 22px;
}

.widget-social.style-2 {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.widget-social.style-2 .title-widget {
  line-height: 26px;
  margin-right: 17px;
}

.widget-social.style-2 ul {
  margin-right: 3px;
}

.widget-social.style-2 ul li {
  margin-left: 12px;
}

.widget-social.style-2 ul li:first-child {
  margin-left: 0px;
}

.widget-social.style-2 ul li a {
  font-size: 26px;
  line-height: 26px;
  color: #7a798a;
}

.widget-social.style-2 ul li a:hover {
  color: var(--primary-color3);
}

.is_dark .widget-social.style-2 ul li a:hover {
  color: var(--primary-color2);
}

.widget-social.style-3 ul li a {
  background-color: #fff;
  color: #14141f;
}

.widget-social.style-3 ul li a:hover {
  color: #fff;
  background-color: var(--primary-color3);
}

.widget-social.style-3 ul li:last-child {
  margin-right: 0;
}

.widget-social.style-3 ul li a {
  width: 40px;
  height: 40px;
  border-radius: var(--border-radius-md);
  text-align: center;
  justify-content: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  font-size: 25px;
}

.widget-social.style-3 ul li.style-2 a {
  font-size: 22px;
}

/* widget-menu */
.widget-menu {
  padding-top: 18px;
}

.widget-menu.style-1 {
  padding-left: 30px;
}

.widget-menu.style-2 {
  padding-left: 38px;
}

.widget-menu.fl-st-3 {
  padding-left: 33px;
}

.widget-menu .title-widget {
  margin-bottom: 24px;
}

.widget-menu ul li {
  margin-bottom: 14px;
}

.widget-menu ul li:last-child {
  margin-bottom: 0px;
}

.widget-menu ul li a {
  font-size: 14px;
  line-height: 22px;
  position: relative;
}

.widget-menu ul li a::after {
  content: "";
  width: 0;
  height: 1px;
  position: absolute;
  background: var(--primary-color3);
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.widget-menu ul li a:hover::after {
  width: 100%;
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* widget-subcribe */
.widget-subcribe {
  padding-top: 17px;
  padding-left: 20px;
}

.widget-subcribe .title-widget {
  margin-bottom: 25px;
}

.widget-subcribe .sub-widget-subcribe {
  padding-right: 5px;
  margin-bottom: 23px;
}

.widget-subcribe .form-subcribe form {
  position: relative;
}

.widget-subcribe .form-subcribe input {
  border-radius: var(--border-radius-md);
  background: #f8f8f8;
  width: 100%;
  padding: 13px 50px 12px 22px;
  font-size: 14px;
  border: 1px solid rgba(138, 138, 160, 0.3);
}

.is_dark .widget-subcribe .form-subcribe input {
  background: transparent;
}

.widget-subcribe .form-subcribe input:focus {
  border: 1px solid var(--primary-color3);
}

.widget-subcribe .form-subcribe input {
  border: 1px solid rgba(138, 138, 160, 0.3);
}

.widget-subcribe .form-subcribe input::placeholder {
  font-size: 14px;
  white-space: nowrap;
  max-width: 70%;
  overflow: hidden;
}

.widget-subcribe .form-subcribe button {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--primary-color3);
  font-size: 21px;
  padding: 15px 18px 14px 19px;
  color: var(--primary-color);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.widget-subcribe .form-subcribe button .icon-fl-send {
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.widget-subcribe .form-subcribe button:hover .icon-fl-send {
  color: #fff;
}

.widget-subcribe .form-subcribe button:hover {
  opacity: 0.9;
}

/* widget-recent-post */
.box-recent-post,
.widget-recent-post ul li.box-recent-post {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 8px 12px 8px 8px;
  border-radius: var(--border-radius-md);
  cursor: default;
  box-shadow: 0px 2px 6px 0px rgb(47 47 47 / 10%);
}

.is_dark .widget-recent-post ul li.box-recent-post {
  background: #343444;
}

.widget-recent-post ul li.box-recent-post {
  margin-bottom: 16px;
}

.widget-recent-post ul li:last-child {
  margin-bottom: 0px;
}

.box-recent-post .box-feature img {
  width: 47px;
  height: 44px;
  border-radius: var(--border-radius-md);
  object-fit: cover;
}

.box-recent-post .box-content {
  padding-left: 10px;
  width: 86%;
}

.box-recent-post .box-content span {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
}

.box-recent-post .box-content .title-recent-post {
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
}

.box-recent-post .box-content .sub-recent-post {
  font-size: 13px;
  line-height: 18px;
  margin-top: 4px;
  color: var(--primary-color4);
}

.box-recent-post .box-content .day-recent-post {
  font-size: 13px;
  line-height: 18px;
  margin-top: 4px;
  color: var(--primary-color9);
}

.box-recent-post .box-content .day-recent-post:hover {
  color: var(--primary-color3);
}

/* widget-tag */
.widget-tag.style-1 {
  margin-right: -5px;
}

.widget-tag ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
}

.widget-tag.style-1 ul li {
  margin-right: 12px;
  margin-bottom: 13px;
}

.widget-tag a.box-widget-tag {
  line-height: 38px;
  padding: 0 28px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 40px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
}

.widget-tag a.box-widget-tag {
  box-shadow: 0px 2px 6px 0px rgb(47 83 109 / 10%);
  background-color: var(--primary-color);
}

.widget-tag a.box-widget-tag:hover {
  background-color: var(--primary-color3);
  color: #fff;
}

.fl-dark .widget-tag a.box-widget-tag {
  background-color: #343444;
}

.fl-dark .widget-tag a.box-widget-tag:hover {
  background-color: var(--primary-color3);
  color: var(--primary-color);
}

.fl-dark .widget-tag a.box-widget-tag {
  background-color: #343444;
}

.fl-dark .widget-tag a.box-widget-tag:hover {
  background-color: var(--primary-color3);
  color: var(--primary-color);
}

.widget-tag.style-2 {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.widget-tag.style-2 .title-widget {
  line-height: 26px;
  margin-right: 15px;
}

.widget-tag.style-2 ul li {
  margin-right: 6px;
}

.widget-tag.style-2 ul li::after {
  content: ",";
}

.widget-tag.style-2 ul li:last-child::after {
  content: "";
}

.widget-tag.style-2 ul li a {
  font-size: 18px;
  line-height: 28px;
}

.widget-tag.style-2 ul li a {
  color: #7a798a;
}

.widget-tag.style-2 ul li a:hover {
  color: var(--primary-color3);
}

.is_dark .widget-tag.style-2 ul li a:hover {
  color: #fff;
}

/* widget-search */
.widget-search form {
  position: relative;
}

.widget-search input {
  border-radius: var(--border-radius-md);
  background: transparent;
  width: 100%;
  padding: 10px 70px 8px 19px;
  font-size: 13px;
  border: 1px solid rgba(138, 138, 160, 0.3);
}

.widget-search input.style-2 {
  padding: 14px 70px 12px 19px;
  font-size: 14px;
}

.widget-search input.style-2::placeholder {
  letter-spacing: 0px;
}

.widget-search input:focus {
  border: 1px solid rgba(52, 52, 68, 1);
  background: 343444;
}

.widget-search input::placeholder {
  font-size: 14px;
  letter-spacing: -0.5px;
}

.widget-search button {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--primary-color3);
  font-size: 17px;
  padding: 12.5px 22px;
  border: none;
  color: #fff;
}

.widget-search button.style-2 {
  padding: 16.5px 22px;
}

.widget-search button i {
  color: #fff;
}

/* widget-filter */
.widget-filter ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.widget-filter.style-2 ul li {
  margin-right: 12px;
  margin-bottom: 13px;
}

.widget-filter a.box-widget-filter i {
  margin-right: 8px;
  color: var(--primary-color3);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.is_dark .widget-filter a.box-widget-filter i {
  color: #fff;
}

.widget-filter a.box-widget-filter.active i,
.widget-filter a.box-widget-filter:hover i {
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.widget-filter a.box-widget-filter {
  line-height: 38px;
  padding: 0 20px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 40px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-color2);
}

.widget-filter a.box-widget-filter {
  box-shadow: 0px 2px 6px 0px rgb(47 83 109 / 10%);
  background-color: var(--primary-color);
}

.widget-filter a.box-widget-filter.active,
.widget-filter a.box-widget-filter:hover {
  background-color: var(--primary-color3);
  color: #ffffff;
}

.widget-filter .btn-filter {
  color: var(--primary-color3);
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
  position: relative;
  cursor: pointer;
}

.widget-filter .btn-filter.style-2 {
  padding-top: 5px;
}

.widget-filter .btn-filter:hover {
  color: var(--primary-color2);
}

.widget-filter.style-1 .header-widget-filter {
  justify-content: space-between;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.widget-filter.style-1 form label {
  line-height: 26px;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
  padding-left: 40px;
}

.widget-filter.style-1 form label.mgbt-none {
  margin-bottom: 0;
}

.widget-filter.style-1 input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  margin-right: 0px;
}

.widget-filter .btn-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 28px;
  height: 28px;
  background-color: transparent;
  border-radius: var(--border-radius-md);
}

.widget-filter .btn-checkbox {
  border: 1px solid #7a798a;
}

.fl-dark .widget-filter .btn-checkbox {
  border: 1px solid #343444;
}

.widget-filter.style-1 form label input:checked ~ .btn-checkbox {
  background-color: var(--primary-color3);
  border: 1px solid var(--primary-color3);
}

.widget-filter .btn-checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

.widget-filter.style-1 form label input:checked ~ .btn-checkbox:after {
  display: block;
}

.widget-filter .btn-checkbox:after {
  content: "\e921";
  font-family: "nfts";
  left: 6px;
  top: 2px;
  color: #fff;
}

/* widget-category */
.widget-category.border-bt {
  border-bottom: 1px solid #ebebeb;
}

.is-dark .widget-category.border-bt {
  border-bottom: 1px solid #343444;
}

.widget-category .title-wg-category {
  justify-content: space-between;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
}

.widget-category .title-wg-category i {
  font-size: 7px;
  padding-top: 9px;
  font-weight: 600;
}

.widget-category .title-widget {
  line-height: 26px;
}

.widget-category form {
  margin-bottom: 23px;
}

.widget-category label {
  line-height: 16px;
  font-size: 14px;
  margin-bottom: 12px;
  position: relative;
  cursor: pointer;
  padding-left: 24px;
  letter-spacing: -0.5px;
}

.widget-category label.mgbt-none {
  margin-bottom: 0;
}

.widget-category .btn-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  background-color: transparent;
  border-radius: 4px;
}

.widget-category .btn-checkbox {
  border: 1px solid #7a798a;
}

.widget-category label input:checked ~ .btn-checkbox {
  background-color: var(--primary-color3);
  border: 1px solid var(--primary-color3);
}

.widget-category .btn-checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

.widget-category label input:checked ~ .btn-checkbox:after {
  display: block;
}

.widget-category .btn-checkbox:after {
  content: "\e921";
  font-family: "nfts";
  font-size: 10px;
  left: 2px;
  top: 0px;
  color: #fff;
}

/* --------------widget-filter-----------*/

.widget-filter a i {
  margin-right: 8px;
}

.widget-filter a.box-widget-filter {
  line-height: 38px;
  padding: 0 20px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 40px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
}

.widget-filter a.box-widget-filter {
  box-shadow: 0px 2px 6px 0px rgb(47 83 109 / 10%);
  background-color: var(--primary-color);
}

.fl-dark .widget-filter a.box-widget-filter {
  background-color: #343444;
}

.fl-dark .widget-filter a.box-widget-filter:hover {
  background: var(--primary-color3);
  color: var(--primary-color);
}

.widget-filter .btn-filter {
  color: var(--primary-color3);
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
  position: relative;
  cursor: pointer;
}

.widget-filter .btn-filter.style-2 {
  padding-top: 5px;
}

.widget-filter .btn-filter:hover {
  color: var(--primary-color2);
}

.fl-dark .widget-filter .btn-filter:hover {
  color: var(--primary-color);
}

.widget-filter.style-1 .header-widget-filter {
  justify-content: space-between;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.widget-filter.style-1 form label {
  line-height: 26px;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
  padding-left: 40px;
}

.widget-filter.style-1 form label.mgbt-none {
  margin-bottom: 0;
}

.widget-filter.style-1 input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  margin-right: 0px;
}

.widget-filter .btn-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 28px;
  height: 28px;
  background-color: transparent;
  border-radius: var(--border-radius-md);
}

.widget-filter .btn-checkbox {
  border: 1px solid #7a798a;
}

.widget-filter.style-1 form label input:checked ~ .btn-checkbox {
  background-color: var(--primary-color3);
  border: 1px solid var(--primary-color3);
}

.widget-filter .btn-checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

.widget-filter.style-1 form label input:checked ~ .btn-checkbox:after {
  display: block;
}

.widget-filter .btn-checkbox:after {
  content: "\e921";
  font-family: "nfts";
  left: 6px;
  top: 2px;
  color: #fff;
}

/* widget-category */
.widget-category.border-bt {
  border-bottom: 1px solid #ebebeb;
}

.is_dark .widget-category.border-bt {
  border-color: #343444;
}

.widget-category .title-wg-category {
  justify-content: space-between;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
}

.widget-category .title-wg-category i {
  font-size: 7px;
  padding-top: 9px;
  font-weight: 600;
}

.widget-category .title-widget {
  line-height: 26px;
}

.widget-category form {
  margin-bottom: 23px;
}

.widget-category label {
  line-height: 16px;
  font-size: 14px;
  margin-bottom: 12px;
  position: relative;
  cursor: pointer;
  padding-left: 24px;
  letter-spacing: -0.7px;
}

.widget-category label.mgbt-none {
  margin-bottom: 0;
}

.widget-category .btn-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  background-color: transparent;
  border-radius: 4px;
}

.widget-category .btn-checkbox {
  border: 1px solid #7a798a;
}

.widget-category label input:checked ~ .btn-checkbox,
.widget-category .btn-checkbox.checked,
.widget-category .btn-checkbox.checked:after {
  background-color: var(--primary-color3);
  border: 1px solid var(--primary-color3);
}

.widget-category .btn-checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

.widget-category label input:checked ~ .btn-checkbox:after,
.widget-category .btn-checkbox.checked:after {
  display: block;
}

.widget-category .btn-checkbox:after {
  content: "\e921";
  font-family: "nfts";
  font-size: 10px;
  left: 2px;
  top: 0px;
  color: #fff;
}

.wc-container > div,
.wc-container > div button {
  color: rgb(26, 26, 26) !important;
}

.wc-container > div > div > div > div {
  max-width: 100% !important;
  width: 50rem !important;
}

/* Loading spinner*/
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.load-spinner {
  position: relative;
}

.load-spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid var(--primary-color2);
  border-top-color: transparent;
  animation: spinner 0.6s linear infinite;
}

.load-spinner.load-spinner--small {
  width: 20px;
  height: 20px;
  display: inline-block;
}

.load-spinner.load-spinner--medium {
  width: 32px;
  height: 32px;
}

.load-spinner.load-spinner--large {
  width: 60px;
  height: 60px;
}

.load-spinner.load-spinner--large:before {
  border-width: 4px;
}

.load-spinner.load-spinner--xlarge {
  width: 144px;
  height: 144px;
}

.load-spinner.load-spinner--xlarge:before {
  border-width: 6px;
}

.load-spinner.load-spinner--xlarge.load-spinner--responsive {
  width: 132px;
  height: 132px;
}

.load-spinner.load-spinner--xxlarge {
  width: 144px;
  height: 144px;
}

.load-spinner.load-spinner--xxlarge:before {
  border-width: 8px;
}

.load-spinner--absolute {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.load-spinner--centered {
  margin: auto;
  margin-top: 180px;
}

.body--container > .load-spinner {
  margin: 100px auto;
}

.center-margin {
  margin-left: auto;
  margin-right: auto;
}

.createButton,
.createButton:focus {
  max-height: 250px;
  max-width: 250px;
  padding: 0;
  text-align: center;
  position: relative;
  border-radius: var(--border-radius-md);
  background-color: var(--primary-color);
  border: none;
}

.createButton:hover {
  border: 1px solid var(--hover-color3);
  background-color: var(--hover-color3);
  border: none;
}

.createButtonText {
  color: var(--primary-color6);
}

.createButtonWrapper {
  border-radius: var(--border-radius-md);
  border: 1px solid rgb(229, 232, 235);
  cursor: pointer;
  height: 100%;
  padding: 12px 12px 20px;
}

button:disabled .createButtonWrapper {
  cursor: default;
}

.createFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 50px;
}

.createButtonFlex {
  display: flex;
  justify-content: space-between;
  max-width: max-content;
  width: 100%;
  gap: 30px;
}

/*ethos-wallet*/
#ethos-wallet-iframe {
  z-index: 2000 !important;
}

.messageAlert {
  position: absolute;
  padding: 1rem;
  background-color: green;
  color: white;
  top: 0;
  right: 0;
  z-index: 3000;
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.h-100 {
  height: 100%;
}

.fullWidth {
  width: 100%;
}

.fl-right {
  float: right;
}

.fl-left {
  float: left;
}

.content-left .media img {
  width: 100%;
  object-fit: cover;
}

.flex-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.required h4:after {
  color: #e32;
  content: " *";
  display: inline;
}

.disabled-link {
  pointer-events: none;
}

.searchResultsIcon {
  border-radius: 5px;
}

.searchResultsLabel {
  margin-left: 20px;
  font-size: 18px;
  font-weight: 500;
}

/* custom status coloured background span */

.sc-status {
  display: inline-block;
  font-weight: normal;
  font-size: medium;
  padding: 10px 20px;
  border-radius: var(--border-radius-md);
}

.sc-status.good {
  background-color: #47a432;
}

.sc-status.neutral {
  background-color: yellow;
  color: black;
}

.sc-status.twice-good {
  font-weight: bolder;
}

.sc-status.bad {
  background-color: #df4949;
}

.sc-status.half-bad {
  background-color: #9835fb;
}

/* positioning utils */
.ps-abs-bl {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.ps-abs-br {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.ps-abs-tr {
  position: absolute;
  top: 0px;
  right: 0px;
}

.ps-abs-tl {
  position: absolute;
  top: 0px;
  left: 0px;
}

.ps-abs-mdl {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ps-abs-bmdl {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%);
}

.ps-abs-b {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%);
}

.capitalize {
  text-transform: capitalize;
}

@keyframes animateGlow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}

.animatedGlow {
  position: relative;
  border-radius: var(--border-radius-md);
}

.animatedGlow::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  filter: blur(30px);
  background-size: 200% 200%;
  animation: animateGlow 1.25s linear infinite, move5 10s infinite linear;
  opacity: 0.8;
  background-image: linear-gradient(
    to left,
    #2349c5,
    #0058c2,
    #0064bb,
    #006db0,
    #2374a3,
    #006db0,
    #0064bb,
    #0058c2,
    #2349c5
  );
  transition: all 0.7s ease;
  -webkit-transition: all 0.7s ease;
}

.fa-heart.fa {
  color: #ff4033;
}

.hightlightedWord {
  color: #569e9e;
}

/* Top Collections */
.top-collections-column {
  margin-bottom: 30px;
  gap: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-auto-flow: column;
}

@media only screen and (max-width: 1200px) {
  .top-collections-column {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }
}

.top-collections-row {
  padding: 12px;
  border-radius: var(--border-radius-md);
  background: var(--primary-color);
  border: 2px solid var(--primary-color7);
  transition: all 0.3s;
}

.top-collections-row:hover,
.top-collections-row:focus {
  background: var(--hover-color);
  border: 2px solid var(--primary-color3);
}

.collection-rank-container {
  align-items: center;
  justify-content: center;
}

.collection-rank {
  width: 16px;
  font-size: 14px;
  font-weight: 800;
}

.collection-logo-container {
  height: 60px;
  min-width: 60px;
  background: #1f1f2c;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
}

.collection-logo-round {
  border-radius: 50%;
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.text-container {
  overflow: hidden;
  flex: 1 1 auto;
  justify-content: space-around;
  font-size: 16px;
  align-items: flex-start;
  margin-right: 16px;
}

.collection-text {
  height: 18px;
  width: 100%;
  padding: 0 5px;
}

.collection-name {
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.collection-creator {
  font-size: 16px;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.collection-floor {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-color4);
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.collection-volume-container {
  overflow: hidden;
  flex: 0 0 auto;
  justify-content: space-around;
  text-align: right;
}

.collection-volume {
  height: 18px;
  width: 96px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
}

.volume-percent-negative {
  color: #e62929;
}
.volume-percent-positive {
  color: #34c77b;
}

/* Royalties */
.royalties-input {
  background-color: rgb(55, 47, 71);
  border-radius: 0px 16px 16px 0px !important;
  box-shadow: rgba(74, 74, 104, 0.1) 0px 2px 2px -1px inset;
  color: rgb(244, 238, 255);
  display: block;
  font-size: 1rem;
  font-weight: 700;
  padding: 0px 16px;
  width: 25%;
  border: 1px solid rgb(38, 33, 48);
}

.royalties-input::placeholder {
  color: gray !important;
}

.royalties-button {
  border: 0px none !important;
  border-radius: 0px;
  justify-content: center;
  width: 25%;
  color: #343a40;
  padding: 15px 39px;
}

.royalties-button:first-child {
  border-radius: 16px 0px 0px 16px;
}

.royalties-button:focus,
.royalties-button-selected {
  color: #14141f !important;
  background-color: #fff;
}

.collection-img-featured {
  height: 160px;
  width: 100%;
  object-fit: cover;
}

.launchpad-img-featured {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.launchpad-img-main {
  height: 675px;
  width: 100%;
  object-fit: contain;
  position: absolute;
  left: 0;
}

.launchpad-img-blur {
  height: 675px;
  width: 100%;
  object-fit: cover;
  position: relative;
  filter: blur(20px);
}

.launchpad-img-row {
  width: 6rem;
  height: 6rem;
  border-radius: 50px;
}

.launchpad-details-img-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 30px;
  max-height: 675px;
}

.listing-img-featured {
  height: 600px;
  width: 100%;
  object-fit: cover;
}

.collection-img-logo {
  height: 72px;
  width: 72px;
  border: 4px solid var(--primary-color);
  border-radius: var(--border-radius-md);
  object-fit: cover;
  overflow: hidden;
  background-color: var(--primary-color);
}

.collection-card {
  padding: 0;
  position: relative;
}

.collection-details {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.collection-details .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.launchpad-card-details {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
}

.launchpad-card-button {
  padding: 9px 0;
}

.tooltip-nowrap {
  min-width: max-content;
}

.stickyPreview {
  position: sticky;
  top: 118px;
}

.inheritHeight {
  max-height: inherit;
}

.reverseSVG {
  transform: scale(-1);
}

.pagination {
  padding: 3rem 0;
  display: flex;
  justify-content: center;
}

.is_dark .pagination .page-link {
  background-color: #1f1f2c;
  color: #fff;
}

.is_dark .page-item.disabled span {
  color: rgb(52, 52, 68) !important;
  border-color: rgb(52, 52, 68) !important;
}

.react-loading-skeleton {
  z-index: 0 !important;
}

#reset-banner {
  background-color: #8a208c;
  padding: 4px 18px;
  border-radius: 0 0 25px 25px;
  text-align: center;
}

#reset-banner p {
  color: #fff;
}

#outage-banner {
  background-color: #ff9935;
  color: #000;
  padding: 6px 18px;
  text-align: center;
  width: 100%;
  font-size: 15px;
  font-weight: 700;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

@media only screen and (max-width: 992px) {
  .createFlex {
    gap: 20px;
  }

  .createButtonFlex {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.priceFieldWrapper {
  align-items: center;
}

.priceField {
  -moz-appearance: textfield;
}

.priceTo {
  margin: 0 8px;
  font-size: 16px;
}

.item-count {
  min-width: max-content;
}

.count-and-search {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.min-width-max-content {
  min-width: max-content;
}

.object-fit {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.launchpad-feature-button {
  width: 250px;
  max-width: 100%;
}

.launchpad-feature-button span {
  margin: auto;
  width: fit-content;
  display: block;
}

.form-seperator {
  border-top: solid 5px var(--primary-color);
  margin-top: 8px;
  padding: 10px;
}

/* Rewards */
.rewards-level-textbox {
  position: absolute;
  left: 20px;
  margin-left: 30px;
  border-radius: var(--border-radius-md);
  box-shadow: 0px 3px 16px 0px #2f536d1f;
  height: 100%;
}

.rewards-level-textbox.locked {
  border: 1px solid #707070;
}

.rewards-level-textbox.unlocked {
  border: 1px solid var(--primary-color11);
}

.rewards-level-image {
  height: 100%;
  position: absolute;
  right: 20px;
  display: flex;
  margin-right: 30px;
  width: 125px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: var(--border-radius-md);
}

.rewards-level-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.rewards-level-image.locked {
  border: 1px solid #707070;
}

.rewards-level-image.locked img {
  filter: grayscale(1);
}

.rewards-level-image.unlocked {
  border: 1px solid #569e9e;
}

.rewards-level-textbox-inner {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 100%;
}

.rewards-level-textbox-inner ul {
  margin-top: 1rem;
}

.rewards-level-textbox-inner li {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.25;
  margin: 0.5rem 0;
  list-style: disc;
}

.rewards-level-progress-icon {
  width: 3rem;
  height: 3rem;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-size: 12px;
}

.rewards-level-progress-icon.locked {
  background-color: #707070;
}

.rewards-level-progress-icon.unlocked {
  background-color: #569e9e;
}

.rewards-level-interval {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 20%;
  position: relative;
}

.rewards-bar-wrapper {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 1.5rem;
  height: 750px;
  margin-left: 150px;
  margin-right: 250px;
}

.rewards-hr {
  width: 70px;
  margin: 0;
  position: absolute;
}

.rewards-hr.locked {
  border: 1px solid #707070;
}

.rewards-hr.unlocked {
  border: 1px solid #569e9e;
}

.rewards-tooltip.locked {
  border: 2px solid #569e9e;
  border-radius: var(--border-radius-md);
  font-size: 16px;
}

.profile-image {
  border-radius: var(--border-radius-md);
  border: 4px solid var(--bg-section);
  height: 100%;
  width: 100%;
  position: relative;
  display: block;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  background: var(--bg-section);
}

.game-image {
  border-radius: var(--border-radius-md);
  border: 4px solid var(--bg-section);
  height: 100%;
  width: 100%;
  position: relative;
  bottom: 20%;
  display: block;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  background: var(--bg-section);
}

.coinbox {
  border: 4px solid var(--primary-color);
  border-radius: 50px;
  padding: 15px;
  margin-bottom: 20px;
  word-break: break-word;
  min-height: 178px;
}

.coinManager .coin {
  border-radius: 50px;
  padding: 15px;
  word-break: break-word;
}
.coinManager .coin.selected {
  border: 4px solid var(--primary-color3);
  padding-left: 11px;
  padding-top: 11px;
}

.subtext {
  margin-top: 0.5rem;
  font-size: 12px;
  font-weight: 400;
}

.dark-overlay {
  background-image: linear-gradient(to bottom, #181a21b0 60%, transparent 120%);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.price .winning-bid {
  color: var(--primary-color11) !important;
}

.price .losing-bid {
  color: var(--primary-color11) !important;
}

.bidInput {
  color: var(--primary-color2) !important;
  font-weight: 500;
}

.full-width {
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
  max-width: 1440px;
}

.rental-info {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.icon-button,
.icon-button:hover,
.icon-button:focus {
  background: transparent;
  font-size: 20px;
  border: none;
  padding: 1rem;
}

.icon-button.delete {
  color: red;
}
.icon-button.create {
  color: green;
}

.icon-button:hover {
  color: #fff;
}

.sorting-flex {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.card-details {
  padding: 1rem 2rem;
  position: relative;
}

.hover-icon {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 1rem;
  left: 1rem;
  transform: unset;
  z-index: 1;
  opacity: 0;
  transition: all 0.4s ease;
}

.sc-card-product:hover .hover-icon {
  opacity: 1;
}

.pre-code-block {
  position: relative;
  display: block;
  font-size: 16px;
  border-radius: 0.75rem;
  padding: 2rem;
  box-shadow: inset 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
}

.is_dark .pre-code-block {
  background-color: var(--primary-color12);
  color: #cacaca;
}

.active-mint {
  border: 2px solid var(--primary-color11) !important;
}

input.picker[type="datetime-local"] {
  position: relative;
}

input.picker[type="datetime-local"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  color: transparent;
  background: transparent;
}

.bordered-tooltip.popup {
  border: 2px solid var(--primary-color3);
  border-radius: var(--border-radius-md);
  font-size: 16px;
}

.infocircle {
  --fa-primary-color: `#fff;
  --fa-secondary-color: var(--primary-color3);
  --fa-secondary-opacity: 1;
  cursor: pointer;
  font-size: 20px;
}

.transak_modal-overlay {
  z-index: 1100 !important;
}

.transak_modal {
  z-index: 1101 !important;
}

.form-tabs {
  z-index: 9;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  transition: all 0.5s ease;
}

.form-tabs .react-tabs__tab-list {
  border: none;
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}

.form-tabs .react-tabs__tab-list li {
  padding: 19px;
  position: relative;
  background: transparent;
  border: 1px solid var(--primary-color3);
  font-size: 16px;
  line-height: 25px;
  color: #8a8aa0;
  transition: all 0.3s ease;
  font-weight: 500;
  border-radius: var(--border-radius-md);
}

.form-tabs .react-tabs__tab-list li:after {
  content: unset;
  border: unset;
}

.form-tabs .react-tabs__tab--selected {
  color: #fff !important;
}

.form-tabs .react-tabs {
  width: 100%;
}

.games .swiper-pagination {
  position: initial;
}

.games .swiper-pagination-bullets {
  display: flex;
  margin-top: 2rem;
  gap: 2rem;
}

.games .swiper-pagination-bullet {
  border-radius: var(--border-radius-md);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.5);
}

.games .swiper-pagination-bullet-active {
  border-radius: var(--border-radius-md);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 1);
}

.games .swiper-slide {
  height: 500px;
}

.games .flat-slider .sub-heading {
  margin: 0;
  font-size: 18px;
}

.games .flat-slider .heading {
  line-height: 1;
  font-size: 42px;
  color: #fff;
}

.games .swiper-slide-active .flat-slider .heading {
  transition-delay: 150ms !important;
}

.games .swiper-slide-active .flat-slider .sub-heading {
  transition-delay: 200ms;
}

.games .swiper-slide-active .flat-slider .flat-bt-slider {
  transition-delay: 250ms;
}

.games .swiper {
  border-radius: 25px;
  overflow: hidden;
}

.five-col {
  width: calc(20% - 20px);
  margin-left: 20px;
}

.featured-games .flat-title-page {
  padding: 120px 0 40px;
}

.games .wrap-heading.flat-slider {
  height: 100%;
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  z-index: 8;
  gap: 1rem;
}

.game-details {
  display: flex;
  height: 100%;
  width: 100%;
}

h2.tf-title {
  font-size: 32px;
  font-weight: 800;
}

h3.nft-text {
  font-size: 20px;
  line-height: 24px;
}

h5.nft-text {
  font-size: 16px;
}

.my-profile .sc-card-product .card-media {
  height: 210px;
}

.horizontal-slider {
  padding: 2rem 0;
  overflow-x: hidden;
  height: 100%;
  position: relative;
}

.horizontal-slider .swiper {
  height: 304px;
}

.horizontal-slider .swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

.horizontal-slider .swiper-wrapper .swiper-slide {
  width: fit-content !important;
  margin-bottom: 0;
}

.horizontal-slider .sc-card-product {
  margin-bottom: 0;
}

.slider-overlay {
  margin: -2rem 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.slider-overlay::before {
  left: 0;
  top: 0;
}

.slider-overlay::before,
.slider-overlay::after {
  background: linear-gradient(to right, rgba(20, 20, 31, 1), rgba(20, 20, 31, 0));
  content: "";
  height: 100%;
  position: absolute;
  width: 200px;
  z-index: 2;
}

.slider-overlay::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.react-player video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gameselect.multiselect .accordion-collapse {
  margin: 1.5rem 0;
}

h2.tf-title {
  font-size: 32px;
  font-weight: 800;
}

h3.nft-text {
  font-size: 20px;
  line-height: 24px;
}

h5.nft-text {
  font-size: 16px;
}

.my-profile .sc-card-product .card-media {
  height: 210px;
}

.horizontal-slider {
  padding: 2rem 0;
  overflow-x: hidden;
  height: 100%;
  position: relative;
}

.horizontal-slider .swiper {
  height: 304px;
}

.horizontal-slider .swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

.horizontal-slider .swiper-wrapper .swiper-slide {
  width: fit-content !important;
  margin-bottom: 0;
}

.horizontal-slider .sc-card-product {
  margin-bottom: 0;
}

.slider-overlay {
  margin: -2rem 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.slider-overlay::before {
  left: 0;
  top: 0;
}

.slider-overlay::before,
.slider-overlay::after {
  background: linear-gradient(to right, rgba(20, 20, 31, 1), rgba(20, 20, 31, 0));
  content: "";
  height: 100%;
  position: absolute;
  width: 200px;
  z-index: 2;
}

.slider-overlay::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.info-tooltip {
  border: 2px solid var(--primary-color3) !important;
  border-radius: var(--border-radius-md) !important;
  text-align: center;
  font-size: 16px !important;
  font-weight: 500 !important;
  max-width: 600px;
}

.rental-tooltip {
  border: 2px solid var(--primary-color3) !important;
  border-radius: var(--border-radius-md) !important;
  text-align: center;
  font-size: 16px !important;
  font-weight: 500 !important;
  max-width: 600px;
  min-width: 250px;
  padding: 2rem !important;
}

.__react_component_tooltip.show.rental-tooltip {
  opacity: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.border-button {
  padding: 1rem 2rem;
  border-radius: var(--border-radius-md);
  background: var(--primary-color);
  border: 2px solid var(--primary-color7);
  animation: rainbow 2s ease-in-out infinite;
}

.border-button:hover,
.border-button:focus {
  background: linear-gradient(var(--primary-color), var(--primary-color)) padding-box,
    linear-gradient(to right, darkblue, darkorchid) border-box;
  border-radius: var(--border-radius-md);
  border: 2px solid transparent;
  background-size: 200% 200%;
}

.border-button:hover .exp,
.border-button:focus .exp {
  /* color: rgba(0, 0, 0, 0) !important; */
  color: var(--primary-color2) !important;
}

.border-button .exp {
  margin-top: 0;
  line-height: unset;
}

.border-button .exp::after {
  content: none;
}

.swiper-slide .sc-card-product a:hover,
.swiper-slide .sc-card-product a:focus {
  color: unset;
}

.gap-1 {
  gap: 1rem;
}

.verified-badge {
  display: inline-block;
  position: absolute;
  bottom: -4px;
  right: -4px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 20px;
  padding: 0 !important;
}

.inline-badge {
  position: unset;
  height: 30px;
  width: 30px;
  line-height: 30px;
  font-size: 30px;
}
